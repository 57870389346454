
import React, { useEffect, useState, useRef } from 'react'
import Autosuggest from 'react-autosuggest';
import styles from './Search.module.css'
import theme from './theme.module.css';

function Search({ items, filterMarkers, refreshMap, searchValue, setSearchValue }) {
    const [suggestions, setSuggestions] = useState([]);
    const searchInput = useRef('');

    useEffect(() => {
    }, [items])

    function getSuggestions(value) {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : items.filter(item => item.name.toLowerCase().includes(inputValue));
    }

    function getSuggestionValue(suggestion) {
        return suggestion.name;
    }

    function onChange(event, { newValue }) {
        const markers = items.filter(x => x.name.toLowerCase().includes(newValue.toLowerCase()));
        setSearchValue(newValue);
        filterMarkers(markers)

    }

    function onSuggestionsFetchRequested({ value }) {
        setSuggestions(getSuggestions(value));
    }

    function onSuggestionsClearRequested() {
        setSuggestions([]);
    }

    function clearSearch() {
        filterMarkers([]);
        setSearchValue('');
        refreshMap();
    }

    function renderSuggestion(suggestion) {
        return (<>
            <div className={styles.items}>
                <div className={styles.logo}><img src={suggestion.icon_link || suggestion.imageUrl} alt="logo" /></div>
                <div className={styles.suggestionItems}>
                    <div className={styles.name}>{suggestion.name && suggestion.name.length > 30 ? suggestion.name.substring(0, 30) + '...' : suggestion.name}</div>
                    <div className={styles.countMembers}>{suggestion.count_members} удзельника(ў)</div>
                </div>
            </div>
        </>
        )
    }

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
        placeholder: 'Пошук',
        value: searchValue,
        onChange: onChange
    };

    return (
        <div className={styles.search}>
            <Autosuggest
                ref={searchInput}
                theme={theme}
                suggestions={suggestions}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
            />
            {searchValue && <button className={styles.clearSearch} onClick={() => { clearSearch() }}>X</button>}
        </div>
    )
}

export default Search;
