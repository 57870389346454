import React, { memo } from 'react';
import styles from './SideDrawer.module.css';
import ClickAwayListener from 'react-click-away-listener';


const SideDrawer = ({ drawerToggleClickHandler }) => (

    <ClickAwayListener onClickAway={drawerToggleClickHandler}>
        <nav className={styles.sideDrawer}>
            <div>
                <ul>
                    <li><img className={styles.pin} src="./pin.svg" /></li>
                    <li><a  href="https://tips.dze.chat">Гайды</a></li>
                    <li><a  href="https://t.me/dzechat_bot">Падтрымка</a></li>       
                    <li><a  href="https://flags.dze.chat">Сцягі</a></li>
                    <li><a className={styles.red} id="contact" href="http://t.me/dzechat_bot">+ Дадаць</a></li>
                </ul>
            </div>
            <div className={styles.spacer} />
            <div className={styles.meta}><img src="./logo.svg" /> - размовы з суседзямі</div>
        </nav>
    </ClickAwayListener>
    
);

export default memo(SideDrawer);