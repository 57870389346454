import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import Header from '@components/Header'
import Loading from '@components/Loading'
import Map from '@components/Map'

import { getApi } from '@services/api'
import endpoints from '@config/endpoints'

function App() {
  let history = useHistory();
  const [markers, setMarkers] = useState([])
  const [channels, setChannels] = useState([])
  const [flags, setFlags] = useState([])
  const [initialLocation, setInitialLocation] = useState(null)
  const [totalCount, setTotalCount] = useState(0)
  const [isChannels, setIsChannels] = useState(history.location.pathname === '/channels');
  const [isFlags, setIsFlags] = useState(history.location.pathname === '/flags');
  const [isChats, setIsChats] = useState(history.location.pathname === '/chats' || history.location.pathname === '/');
  const [searchValue, setSearchValue] = useState('')
  const [filteredMarkers, setFilteredMarkers] = useState([])
  const [events, setEvents] = useState([]);
  const [xsfr, setXsfr] = useState(null);
  const iniFrame = window.self !== window.top;

  useEffect(() => {
    loadMarkers("")
  }, [])

  useEffect(() => {
  }, [filteredMarkers]);

  function filterMarkers(markers) {
    setFilteredMarkers(markers);
  }

  function loadMarkers(params) {
    getApi(endpoints.markers, params)
      .then(result => {
        setMarkers(result.markers)
        setChannels(result.channels)
        setEvents(result.events)
        setFlags(result.flags)
        // setInitialLocation(result.location)
        setInitialLocation([48.9, 13.5667])
        setTotalCount(result.count)
        setXsfr(result.xsfr);
      })
      .catch(err => {
        // TODO handle error
        console.error(err)
      })
  }

  function refreshMap() {

  }

  function changeType(type) {
    switch (type) {
      case 'channels': {
        setIsChannels(true);
        setIsFlags(false);
        setIsChats(false);
        break;
      }
      case 'chats': {
        setIsChats(true);
        setIsChannels(false);
        setIsFlags(false);
        break;
      }
      case 'flags': {
        setIsFlags(true);
        setIsChats(false);
        setIsChannels(false);
        break;
      }
    }
    setSearchValue('');
    setFilteredMarkers([]);
  }

  function getItems() {
    if (filteredMarkers && filteredMarkers.length !== 0 && searchValue) {
      return filteredMarkers;
    }

    if (isChannels) {
      return channels;
    }

    if (isChats) {
      return markers;
    }

    if (isFlags) {
      return flags;
    }
  }

  return (
    <>
      <Header hide={iniFrame} refreshMap={refreshMap} count={totalCount} filterMarkers={filterMarkers} items={getItems()} isChannels={isChannels} isChats={isChats} isFlags={isFlags} searchValue={searchValue} setSearchValue={setSearchValue} changeMarkersType={changeType} />
      {initialLocation ? (
        <Map
          selectedMarker={filteredMarkers && filteredMarkers.length !== 0 ? filteredMarkers[0] : null}
          location={initialLocation}
          events={events}
          flags={flags}
          isFlags={isFlags}
          hideMarkers={iniFrame}
          markers={getItems()}
          loadMarkers={loadMarkers}
          xsfr={xsfr}
        />
      ) : (
        <Loading />
      )}
    </>
  )
}

export default App
