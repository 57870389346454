import React, { memo, useEffect } from 'react'
import { createMapLayers, initMap } from '@services/map'
import styles from './Map.module.css'

const ID = 'map'
var isLoaded = false

function Map({
  location,
  markers,
  loadMarkers,
  selectedMarker,
  events,
  flags,
  isFlags,
  hideMarkers
}) {
  useEffect(() => {
    if (!isLoaded) {
      initMap(ID, location, loadMarkers, selectedMarker)
      isLoaded = true
    }

    createMapLayers(hideMarkers ? [] : markers, events, flags, isFlags);
  }, [markers, events, flags, hideMarkers]);

  return (
    <div className={styles['map-box']}>
      <div
        className={styles.map}
        id={ID}
        style={{
          width: '100%',
          height: '100%'
        }}
      />
    </div>
  )
}

export default memo(Map)
