import Leaflet from 'leaflet'
import 'leaflet.markercluster/dist/leaflet.markercluster'


import {
  MAP_MAX_ZOOM,
  MAP_MIN_ZOOM,
  MAP_MAX_CLUSTERING_ZOOM,
  MAP_CLUSTER_S_MAX,
  MAP_CLUSTER_M_MAX,
  EVENTS_PIN_ICON_PATH,
  EVENTS_PIN_ICON_PATH_FAV,
  MAP_CHAT_PIN_SMALL_ENABLED
} from '@config/constants'

let map = null

function getSize(count) {
  if (count <= MAP_CLUSTER_S_MAX) {
    return 's'
  }

  if (count <= MAP_CLUSTER_M_MAX) {
    return 'm'
  }

  return 'l'
}

const markers = Leaflet.markerClusterGroup({
  spiderfyOnMaxZoom: true,
  disableClusteringAtZoom: MAP_MAX_CLUSTERING_ZOOM,
  iconCreateFunction: cluster => {
    const count = cluster.getChildCount()

    return Leaflet.divIcon({
      className: 'cluster-box',
      html: `<div class="cluster cluster-${getSize(count)}">${count}</div>`
    })
  }
})

const eventsLayer = Leaflet.layerGroup();

// const  eventsLayer = Leaflet.markerClusterGroup({
//    spiderfyOnMaxZoom: true,
//    disableClusteringAtZoom: MAP_MAX_CLUSTERING_ZOOM,
//    iconCreateFunction: cluster => {
//      const count = cluster.getChildCount()

//      return Leaflet.divIcon({
//        className: 'cluster-box events',
//        html: `<div class="cluster events cluster-${getSize(count)}">${count}</div>`
//      })
//    }
//  })

const flagsLayer = Leaflet.layerGroup();

// const flagsLayer = Leaflet.markerClusterGroup({
//   spiderfyOnMaxZoom: true,
//   disableClusteringAtZoom: MAP_MAX_CLUSTERING_ZOOM,
//   iconCreateFunction: cluster => {
//     const count = cluster.getChildCount()

//     return Leaflet.divIcon({
//       className: 'cluster-box',
//       html: `<div class="cluster cluster-${getSize(count)}">${count}</div>`
//     })
//   }
// })

// eslint-disable-next-line no-unused-vars
export function initMap(id, location, loadMarkers) {
  map = Leaflet.map(id, {
    center: location,
    zoom: MAP_MIN_ZOOM,
    maxZoom: MAP_MAX_ZOOM,
    minZoom: MAP_MIN_ZOOM
  })


  Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
  }).addTo(map);

  // borders
  // let imageOverlay = null
  // var imageUrl = "border.svg"
  // var imageBounds = [
  //   [56.250549500679074, 22.80],
  //   [51.132276, 33.101909]
  // ]

  // imageOverlay = Leaflet.imageOverlay(imageUrl, imageBounds)
  // imageOverlay.addTo(map)
  // map.on('zoom', function (event) {
  //   if (event.target._zoom > 8) {
  //     if (map.hasLayer(imageOverlay)) {
  //       imageOverlay.removeFrom(map)
  //     }
  //   } else if (!map.hasLayer(imageOverlay)) {
  //     imageOverlay.addTo(map)
  //   }
  // });

  map.addLayer(markers, { chunkedLoading: true });
  map.addLayer(eventsLayer, { chunkedLoading: true });
  map.addLayer(flagsLayer, { chunkedLoading: true });
}


export function getRadius() {
  var bounds = map.getBounds();
  var northWest = bounds.getNorthWest();
  var northEast = bounds.getNorthEast();
  var distance = northWest.distanceTo(northEast);

  return distance / 2;
}

//let lastClick = 0;

function onMarkerBlockClick(e, link, /*title*/) {

  // if (!e.sourceTarget._icon.classList.contains("activemarker")) {
  //   [].forEach.call(document.getElementsByClassName('activemarker'), function (el) {
  //     el.classList.remove('activemarker');
  //   });
  //   e.sourceTarget._icon.classList.add('activemarker');
  // }

  // if (lastClick >= (Date.now() - 200)) { // small hack to prevent twice fider click on mobile devices (touch ? )
  //   e.originalEvent.preventDefault();
  //   e.originalEvent.stopPropagation();

  //   return;
  // }

  // lastClick = Date.now();

  // if ((e.originalEvent.path && e.originalEvent.path[0].className === 'report') || (e.originalEvent.target && e.originalEvent.target.className === 'report')) {
  //   alert(title);
  // }
  // else if ((e.originalEvent.path && e.originalEvent.path[0].className === 'joinLink') || (e.originalEvent.target && e.originalEvent.target.className === 'joinLink')) {
  //   link = link + '/';
  //   const linkParts = link.split('/').filter(x => !!x);

  //   if (linkParts.filter(x => x.toLowerCase() === 't.me').length > 0) {
  //     e.originalEvent.preventDefault();
  //     e.originalEvent.stopPropagation();

  //     const isPrivateChat = linkParts.filter(x => x === 'joinchat').length > 0;
  //     const protoUrl = !isPrivateChat ? `tg://resolve?domain=${linkParts.pop()}` : `tg://join?invite=${linkParts.pop()}`;
  //     window.location = protoUrl;
  //   }
  // }


  link = link + '/';
  const linkParts = link.split('/').filter(x => !!x);

  if (linkParts.filter(x => x.toLowerCase() === 't.me').length > 0) {
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();

    const isPrivateChat = linkParts.filter(x => x === 'joinchat' || x[0] === '+').length > 0;
    let protoUrl;
    let lastPart = linkParts.pop();

    if (!isPrivateChat) {
      protoUrl = `tg://resolve?domain=${lastPart}`;
    }
    else {
      if (lastPart[0] === '+') {
        lastPart = lastPart.substring(1);
      }

      protoUrl = `tg://join?invite=${lastPart}`;
    }

    window.location = protoUrl;
  }
}

export function createMapLayers(items, events, flags, isFlags) {
  markers.clearLayers();
  eventsLayer.clearLayers();
  flagsLayer.clearLayers();

  let customOptions = {
    'maxWidth': '500',
    'width': '300',
    'className': 'popupCustom flag-popup'
  }

  if (items && isFlags) {
    items.forEach(item => {
      if (item.lat !== undefined && item.long !== undefined) {
        const imagePath = '/flag_holder.svg';
        const icon = Leaflet.divIcon({
          className: 'cluster-box flag-box',
          html: `<img class="flagHolder" src="${imagePath}" alt="event" /><img class="flag" src="${item.imageUrl}" />`
        })
        icon.options.iconSize = [48, 48]
        const marker = new Leaflet.marker(new Leaflet.LatLng(item.lat, item.long), { icon });

        const customPopup = `<div class="flagsContainer">
          <div class="row">
            <a href='${item.imageUrl}' target="_blank"><img src='${item.imageUrl}' class="flagImage image" alt='flag image' width='100px' /></a>
          </div>
          <div class="rowDescription">
            <div class="description">
              <div class="name" title='${item.name}'>${item.name}</div>
              <div class="coordinates">${item.lat} ${item.long}</div>
            </div>
          </div>
        </div>`;

        marker.bindPopup(customPopup, customOptions);
        marker.on('click', function () {
          marker.openPopup();
        });

        flagsLayer.addLayer(marker);
      }

      if (items && items.length === 1) {
        const selectedMarker = items[0];
        map.setView(new Leaflet.LatLng(selectedMarker.lat, selectedMarker.long))
      }
    })
  }

  if (events) {
    events.sort(function(x, y) {return (x.favorite === y.favorite) ? 0 : (x.favorite? 1 : -1);}).forEach(item => {
      if (item.lat !== undefined && item.long !== undefined) {
        const icon = Leaflet.divIcon({
          className: 'cluster-box event',
          iconSize: [48, 48],
          html: `<img src="${item.favorite ? EVENTS_PIN_ICON_PATH_FAV : EVENTS_PIN_ICON_PATH}" alt="event" />`
        })
        // icon.options.iconSize = [40, 40]
        const marker = new Leaflet.marker(new Leaflet.LatLng(item.lat, item.long), { icon });

        const title = item.url ? ` <a href='${item.url}' target="_blank">${item.name}</a>` : item.name;
        const customPopup = `<div class="eventContainer">
        <div class="row">
          <a href='${item.imageUrl}' target="_blank"><img src='${item.imageUrl}' class="image" alt='event image' width='150px' /></a>
        </div>
        <div class="rowDescription">
          <div class="description">
            <div class="name" title='${item.name}'>${title}</div>
            <div class="coordinates"> ${item.date} </div>
          </div>
        </div>
      </div>`;

        marker.bindPopup(customPopup, customOptions);
        marker.on('click', function () {
          marker.openPopup();
        });

        eventsLayer.addLayer(marker);
      }

      if (items && items.length === 1) {
        const selectedMarker = items[0];
        map.setView(new Leaflet.LatLng(selectedMarker.lat, selectedMarker.long))
      }
    })
  }

  if (items && !isFlags) {
    items.forEach(item => {
      if (item.coordinates !== undefined) {
        const count = item.count
        const icon = Leaflet.divIcon({
          className: 'cluster-box chat-icon',
          html: `<div class="cluster cluster-${getSize(count)}">${count}</div>`
        })
        icon.options.iconSize = [48, 48]

        markers.addLayer(new Leaflet.marker(new Leaflet.LatLng(item.coordinates[0], item.coordinates[1]), { icon }))
      } else {
        const text = item.name
          ? String(item.name).replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')
          : ''

          const messageClass = MAP_CHAT_PIN_SMALL_ENABLED ? 'message small' : 'message';
        const icon = Leaflet.divIcon({
          className: 'avatar-box',
          html: `<div class="${messageClass}">
                  ${item.hasSaver ? '<div class="saver-badge"><img class="saver-icon" src="/saver.jpg"></img></div>' : ''}
                  <div class="content">
                    <img title="${text}" class="avatar" src='${item.icon_link}'></img>
                    <div title="${text}" class="title">${text}</div>
                  </div>
                  <div class="hover">
                    <div class="meta">
                      <div class="item"><img src="/group.svg"></img>${item.count_members}</div>
                    </div>
                    <div class="add">
                      <a target="_blank" href='${item.link}' class="joinLink">Далучыцца</a>
                    </div>
                  </div>
                </div>`
        });
        //<div class="item"><a href="javascript:void(0);" class="report">Паскардзіцца</a></div>
        icon.options.iconSize = [48, 48]

        markers.addLayer(new Leaflet.marker(new Leaflet.LatLng(item.lat, item.long), { icon }).on('click', function (e) { onMarkerBlockClick(e, item.link, text) }))
      }

      if (items && items.length === 1) {
        const selectedMarker = items[0];
        map.setView(new Leaflet.LatLng(selectedMarker.lat, selectedMarker.long))
      }
    })
  }
}
