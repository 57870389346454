import React, { useState } from 'react'
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import Search from '../Search';
import styles from './Toolbar.module.css'
import classnames from 'classnames'
import { Link } from "react-router-dom";

const Toolbar = props => {
    const [isToolbarOpened, setIsToolbarOpened] = useState('false');

    return (<header className={styles.toolbar}>
        <nav className={styles.navigation}>
            <div>
                <DrawerToggleButton drawerToggleClickHandler={props.drawerToggleClickHandler} />
            </div>

            <div className={styles.logo}><img src="/logo.svg" /> - размовы з суседзямі</div>
            <div className={classnames(styles.switcherContainer, !isToolbarOpened ? styles.toolbarOpened : styles.toolbarClosed)} onBlur={() => setIsToolbarOpened(false)} onClick={() => setIsToolbarOpened(!isToolbarOpened)}>
                <div className={styles.switcher}>
                    <div className={classnames(styles.menuContainer, !props.isChats && isToolbarOpened ? styles.hiddenMenu : '')}>
                        <div className={styles.left}>
                            <img className={classnames(styles.selectedMenu, !props.isChats ? styles.hidden : '')} src="./layers.svg" alt="" />
                        </div>
                        <div className={styles.right}>
                            <Link to="/chats"><span className={classnames(props.isChats ? styles.selectedTypeLabel : '', styles.typeLabel)} onClick={() => props.changeMarkersType('chats')}>Чаты</span></Link></div>
                    </div>
                    <div className={classnames(styles.menuContainer, !props.isChannels && isToolbarOpened ? styles.hiddenMenu : '')}>
                        <div className={styles.left}>
                            <img className={classnames(styles.selectedMenu, !props.isChannels ? styles.hidden : '')} src="./layers.svg" alt="" />
                        </div>
                        <div className={styles.right}>
                            <Link to="/channels"><span className={classnames(props.isChannels ? styles.selectedTypeLabel : '', styles.typeLabel)} onClick={() => props.changeMarkersType('channels')}>Каналы</span></Link></div>
                    </div>
                    {/* <div className={classnames(styles.menuContainer, !props.isFlags && isToolbarOpened ? styles.hiddenMenu : '')}>
                        <div className={styles.left}>
                            <img className={classnames(styles.selectedMenu, !props.isFlags ? styles.hidden : '')} src="./layers.svg" alt="" />
                        </div>
                        <div className={styles.right}>
                            <Link to="/flags"><span className={classnames(props.isFlags ? styles.selectedTypeLabel : '', styles.typeLabel)} onClick={() => props.changeMarkersType('flags')}>Сцягі</span></Link></div>
                    </div> */}
                </div>
            </div>
            <div className={styles.spacer}>
                <Search searchValue={props.searchValue} setSearchValue={props.setSearchValue} refreshMap={props.refreshMap} filterMarkers={props.filterMarkers} items={props.items} />
            </div>
            <div className={styles.navigationItems}>
                <ul>
                    <li><a className={styles.red} href="http://t.me/dzechat_bot">+ Дадаць</a></li>
                </ul>
            </div>
        </nav>
    </header>)
};

export default Toolbar;