import { create } from 'apisauce'

import { API_BASE_URL } from '@config/constants'

const handleResponse = async response => {
  if (!response.ok) {
    throw response
  }

  return response.data
}

async function request(path, method, data = null, xsfr = null) {
  const headers = {};

  if (xsfr) {
    headers['X-XSRF-TOKEN'] = xsfr;
  }

  const api = create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    headers: headers
  });



  return api[method](path, data)
    .then(handleResponse)
    .catch(response => {
      console.error(response) // TODO handle error
    })
}

export const getApi = (url, params) => request(url, 'get', params)
export const postApi = (url, data, xsfr) => request(url, 'post', data, xsfr)
