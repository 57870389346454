export const API_BASE_URL = 'https://api.dze.chat'

export const MAP_INITIAL_ZOOM = 12
export const MAP_MAX_ZOOM = 18
export const MAP_MIN_ZOOM = 5
export const MAP_MAX_CLUSTERING_ZOOM = 15
export const MAP_CLUSTER_S_MAX = 50
export const MAP_CLUSTER_M_MAX = 150

export const EVENTS_PIN_ICON_PATH = '/kupala.svg';
export const EVENTS_PIN_ICON_PATH_FAV = '/charnobyl.svg';

export const MAP_CHAT_PIN_SMALL_ENABLED = true;
