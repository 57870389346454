import React, { useState } from 'react';
import Toolbar from '../Toolbar';
import SideDrawer from '../SideDrawer/SideDrawer';


// eslint-disable-next-line no-unused-vars
import styles from './Header.module.css'

function Header({
  changeMarkersType,
  hide,
  isChannels,
  isChats,
  isFlags,
  items,
  filterMarkers,
  refreshMap,
  searchValue,
  setSearchValue }) {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false)
  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
  }

  if (hide) {
    return <></>;
  }

  return (
    <>
      <Toolbar searchValue={searchValue} refreshMap={refreshMap} filterMarkers={filterMarkers} isChannels={isChannels} isChats={isChats} isFlags={isFlags} items={items} drawerToggleClickHandler={drawerToggleClickHandler} changeMarkersType={changeMarkersType} setSearchValue={setSearchValue} />
      {sideDrawerOpen && <SideDrawer drawerToggleClickHandler={drawerToggleClickHandler} />}
    </>
  )
}

export default Header;
